.App {
  text-align: center;
  background-color: rgb(43, 43, 43);
  height: 100vh;
}
.box {
  height: 95vh;
}

.content-box {
  height: 95%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.Prompt-Box {
  width: 349px;
  height: 266px;
  padding: 8px 8px 8px 8px;
  background: #eaeaea;
  border-color: #000000;
  border-width: 1px;
  border-style: solid;
}

.Prompt-Header {
  width: 205px;
  height: 51px;
  color: #000000;
  font-family: "Bungee";
  font-weight: 400;
  font-size: 26px;
}

.Prompt-P {
  width: 322px;
  height: 208px;
  color: #000000;
  font-family: "B612";
  font-weight: 400;
  font-size: 18px;
  line-height: 1.3;
}
.YesButton {
  width: 140px;
  height: 50px;
  padding: 8px 8px 8px 8px;
  background: #38a9ff;
  color: #000000;
  border-color: #000000;
  border-width: 1px;
  border-style: solid;
  font-family: "Galindo";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.NoButton {
  width: 140px;
  height: 50px;
  padding: 8px 8px 8px 8px;
  background: #ee5171;
  color: #000000;
  border-color: #000000;
  border-width: 1px;
  border-style: solid;
  font-family: "Galindo";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
.NextButton {
  width: 174px;
  height: 56px;
  padding: 8px 8px 8px 8px;
  background: #6f43ef;
  color: #000000;
  border-color: #000000;
  border-width: 1px;
  border-style: solid;
  font-family: "Galindo";
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-align: center;
}
